import React from 'react';
import './Services.css';

const servicesData = [
  { title: 'Licence Disc Renewals', description: 'Vehicle license disk renewal' },
  { title: 'Registration of New Vehicles', description: 'Licensing of new vehicles - all types' },
  { title: 'Vehicle Licensing', description: 'Licensing of new vehicles - all types' },
  { title: 'De-registration of Motor Vehicles', description: 'Deregistration of all types including motor vehicles, motorcycles, trailers, semi-trailers, trucks, and caravans' },
  { title: 'Change of Address', description: 'Update address to receive licensing reminders or any other notifications' },
  { title: 'Number Plates', description: 'Both normal and personalised, all SA provinces for any vehicle - SANS 1116' },
  { title: 'Notice of Change of Ownership (NCO)', description: 'To be submitted whenever a vehicle is sold' },
  { title: 'Trust/non-profit Organisation Registration', description: 'Register a vehicle to a trust or non-profit organisation' },
  { title: 'Company Registration (BRN)', description: 'Register a vehicle to a trust or non-profit organisation' },
  { title: 'Change of Titleholder', description: 'Change owner details once the car has been paid off at a bank or other financial aid institution' },
  { title: 'Special Permit', description: 'a Temporary vechile license permit that enables one to drive without a license for up to 3 days' },
  { title: 'Temporary permit', description: 'a Temporary vechile license permit that enables one to drive without a license for up to 21 days' },
];

const Services: React.FC = () => {
  return (
    <div className="our-services-container">
      <header className="our-services-header">
        <h1 className="services-title"><span className="header-white">EFFORTLESS</span> <span className="header-green">REGISTRATION</span></h1>
        <h1 className="services-title"><span className="header-white">UNMATCHED</span> <span className="header-green">SERVICE</span></h1>
      </header>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div key={index} className="service-block">
            <h2 className="service-title">{service.title}</h2>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
